import React, { useState, useReducer, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import FormInput from "../../../shared/Form-Input";
import FormSelect from "../../../shared/Form-Select";
import { Divider } from "primereact/divider"
import { RadioButton } from 'primereact/radiobutton';
import "./style.scss"
import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, setEventSuccess, showLoader } from "../../../redux/mainSlice";
import ConfirmationPopup from "../../../shared/ConfirmationPopup";

function reducer(state, action) {
  switch (action.type) {
    case "input_change":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return state;
  }
}

const initialState = {
  event_type_id: "",
  court_ids: [],
  token_cost: null,
  available_slots: null,
  date: "",
  is_recurrent: false,
  frequency: null,
  interval: 1,
  repeat_on: [],
  occurrences: null,
  ends_on: "",
  end_time: "",
  start_time: "",
  is_never: false,
  location_id: ""
};

export default function AddEvent({ visible, onChange, data, type, time, createDate }) {
  const eventSuccess = useSelector((state) => state.mainSlice.eventSuccess);
  const [formData, setFormData] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const toast_Ref = useRef(null);
  const [is_popup, setis_Popup] = useState()
  const navigate = useNavigate();
  const [accepted, setAccepted] = useState(eventSuccess);
  const dispatch = useDispatch();
  const locationId = useSelector((state) => state.mainSlice.locationId);
  const [endsOn, setEndsOn] = useState("");
  const [courts, setCourts] = useState([])
  const [eventTypeloading, setEventTypeLoading] = useState(false)
  const [courtsLoader, setCourtsLoader] = useState(false)
  const [date, setDate] = useState()
  const [endDate, setEndDate] = useState()
  const [occurrences, setOccurrences] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);
  const [edit, setEdit] = useState(false)
  const [editConfirmDialog, setEditConfirmDialog] = useState(false)
  const [eventName, setEventName] = useState("")
  const [selectedeventType, setSelectedeventType] = useState("")
  const confirmationPopupRef = useRef(null);
  const [showError, setShowError] = useState("")

  const daysOfWeek = [
    { name: "M", label: 'MO' },
    { name: 'T', label: 'TU' },
    { name: 'W', label: 'WE' },
    { name: 'T', label: 'TH' },
    { name: 'F', label: 'FR' },
    { name: 'S', label: 'SA' },
    { name: 'S', label: 'SU' },
  ];

  const handleChange = (e) => {

    if (e.name == "frequency") {
      setRepeatOn()
      setFormData((prevFormData) => ({ ...prevFormData, ["repeat_on"]: null }));
    }

    if (e.name == "event_type_id") {
      //setCourts([]);
      setEventName(eventTypes.find(item => item.id === e.value)?.name);
      setSelectedeventType(eventTypes.find(item => item.id === e.value)?.type);
      //setFormData((prevFormData) => ({ ...prevFormData, ["court_ids"]: [] }));
      setFormData((prevFormData) => ({ ...prevFormData, ["location_id"]: locationId }));

      let event_type = eventTypes.find(item => item.id === e.value)?.type;

      if(event_type !== "scheduled_play"){
        setFormData((prevFormData) => ({ ...prevFormData, ["available_slots"]: null }));
      }
    }

    if (e.name == "date") {
      setDate(e.value)
      setFormData((prevFormData) => ({ ...prevFormData, [e.name]: formatDate(e.value) }));
    } else if (e.name == "end_date") {
      setEndDate(e.value)
      setFormData((prevFormData) => ({ ...prevFormData, [e.name]: formatDate(e.value) }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [e.name]: e.value }));
    }

  };

  const onSubmit = async () => {
    //e.preventDefault();
    if (edit) {
      if (is_popup) {
        setEditConfirmDialog(true)
      } else {
        confirmationPopupRef.current.showPopUp()
      }
    } else {
      dispatch(showLoader())
      try {
      const response = await httpClient.post("/calender-event", formData);      
      
      if (response?.status === 200) {
        toast_Ref.current?.showMessage(
          "success",
          edit ? "Reservation Slot Updated Successfully" : "Reservation Slot Created Successfully",
          "",
          "ic-square-check"
        )
        setDate("");
        setEndsOn("")
        setAccepted(!accepted)
        dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
        setTimeout(() => {
        setFormData(initialState)
        onChange()
      }, 1000);
      }
      
      } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      }

      dispatch(hideLoader())
    }
  };


  const fetchEventTypes = async () => {
    setEventTypeLoading(true)
    setCourts([])
    try {
      const { data } = await httpClient.get("/lookup-event-type");

      setEventTypes(data.data);
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
    }
    setEventTypeLoading(false)
  };

  const fetchCourts = async () => {
    setCourtsLoader(true)
    try {
      const { data } = await httpClient.get(`/lookup-courts/${locationId}`);
      
      setCourts(data.data);
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
    }
    setCourtsLoader(false)
  };

  const onCancel = async () => {
    setDate("");
    setEndsOn("")
    onChange();
    setEdit(false)
    setFormData(initialState)
  }


  const footerContent = (<>
    <div className="flex gap-2 justify-content-end">
      {showError && (<p className="error-message-add-event"><i className="ic-exclamation-circle"></i> You cxan not edit the reservation slot <br /> &nbsp;&nbsp;&nbsp;&nbsp; as court 1 has been booked.</p>)}
      <Button
        onClick={onCancel}
        className="w-full btn-secondary"
        label="Close"
        disabled={false}
      />
      <Button
        className="w-full btn-primary"
        label="Confirm"
        onClick={onSubmit}
        disabled={formData.event_type_id == "" || formData.court_ids == "" || formData.date == "" || formData.end_time == ""
          || formData.start_time == "" || formData.token_cost == null || ( selectedeventType == "scheduled_play" && formData.available_slots == null ) || ( selectedeventType == "scheduled_play" && formData.available_slots == 0 ) || (formData.is_recurrent === true &&
            (formData.frequency === null || formData.interval === null || endsOn === "")) || (endsOn === "On" &&
              endDate === "") || (endsOn === "After" &&
                formData.occurrences === null) || (formData.frequency === "week" && (formData.frequency === "week"
                  ? (formData.repeat_on && formData.repeat_on.length === 0)
                  : formData.repeat_on === null))
        }
      />
    </div>
  </>
  );
  
const setRepeatOn = (repeatOnArray = [],condition) => {
  if (condition) {
    // Filter days of the week based on the repeatOnArray
    const selectedDaysFromFormData = daysOfWeek.filter(day =>
      repeatOnArray.includes(day?.label)
    );
    
    setSelectedDays(selectedDaysFromFormData);
  } else {
    setSelectedDays([]); // Clear selected days if not repeating weekly
  }
};

  useEffect(() => {
    // fetchEventTypes()
       
  }, []);


  const handleEndTimeSelect = (endTime) => {

    // List of time slots as options
    const timeSlots = [
      { code: "12:00 AM to 1:00 AM", name: "12:00 AM to 1:00 AM" },
      { code: "1:00 AM to 2:00 AM", name: "1:00 AM to 2:00 AM" },
      { code: "2:00 AM to 3:00 AM", name: "2:00 AM to 3:00 AM" },
      { code: "3:00 AM to 4:00 AM", name: "3:00 AM to 4:00 AM" },
      { code: "4:00 AM to 5:00 AM", name: "4:00 AM to 5:00 AM" },
      { code: "5:00 AM to 6:00 AM", name: "5:00 AM to 6:00 AM" },
      { code: "6:00 AM to 7:00 AM", name: "6:00 AM to 7:00 AM" },
      { code: "7:00 AM to 8:00 AM", name: "7:00 AM to 8:00 AM" },
      { code: "8:00 AM to 9:00 AM", name: "8:00 AM to 9:00 AM" },
      { code: "9:00 AM to 10:00 AM", name: "9:00 AM to 10:00 AM" },
      { code: "10:00 AM to 11:00 AM", name: "10:00 AM to 11:00 AM" },
      { code: "11:00 AM to 12:00 PM", name: "11:00 AM to 12:00 PM" },
      { code: "12:00 PM to 1:00 PM", name: "12:00 PM to 1:00 PM" },
      { code: "1:00 PM to 2:00 PM", name: "1:00 PM to 2:00 PM" },
      { code: "2:00 PM to 3:00 PM", name: "2:00 PM to 3:00 PM" },
      { code: "3:00 PM to 4:00 PM", name: "3:00 PM to 4:00 PM" },
      { code: "4:00 PM to 5:00 PM", name: "4:00 PM to 5:00 PM" },
      { code: "5:00 PM to 6:00 PM", name: "5:00 PM to 6:00 PM" },
      { code: "6:00 PM to 7:00 PM", name: "6:00 PM to 7:00 PM" },
      { code: "7:00 PM to 8:00 PM", name: "7:00 PM to 8:00 PM" },
      { code: "8:00 PM to 9:00 PM", name: "8:00 PM to 9:00 PM" },
      { code: "9:00 PM to 10:00 PM", name: "9:00 PM to 10:00 PM" },
      { code: "10:00 PM to 11:00 PM", name: "10:00 PM to 11:00 PM" },
      { code: "11:00 PM to 12:00 AM", name: "11:00 PM to 12:00 AM" },
    ];


    const selectedIndex = timeSlots.findIndex(slot => slot.name.split(" to ")[1].trim() === endTime.trim());

    if (selectedIndex > 0) {
      const startTime = timeSlots[selectedIndex].name.split(" to ")[0];
      const endTime = timeSlots[selectedIndex].name.split(" to ")[1];

      setFormData({
        ...formData,
        start_time: startTime,
        end_time: endTime,
      });
    } else if (selectedIndex === 0) {
      const startTime = timeSlots[selectedIndex].name.split(" to ")[0];
      const endTime = timeSlots[selectedIndex].name.split(" to ")[1];

      setFormData({
        ...formData,
        start_time: startTime,
        end_time: endTime,
      });
    } else {
    }
  };


  const onLoad = async () => {
    
    setCourts([])
    
    if (data) {
      const editData = data[0]
      setEdit(true)
      await fetchEventTypes()
      await fetchCourts()
      const [year, month, day] = editData.occurrence_date.split('-').map(Number);
      setDate(new Date(year, month - 1, day))
      setEventName(eventTypes.find(item => item.id === editData.event_type_id)?.name)
      setSelectedeventType(eventTypes.find(item => item.id === editData.event_type_id)?.type)
      setis_Popup(editData?.is_popup)

      setFormData({
        event_type_id: editData?.event_type_id,
        court_ids: editData?.courts.map(court => court.court_id),
        token_cost: editData?.token_cost,
        date: editData?.occurrence_date,
        start_time: editData?.start_time,
        end_time: editData?.end_time,
        event_id: editData?.event_id,
        edit_occurrence_date: editData?.occurrence_date,
        edit_start_time: editData?.start_time,
        edit_end_time: editData?.end_time,
        edit_court_id: type === "day" ? editData?.courts.map(court => court.court_id) : null,
        is_recurrent: editData?.is_recurrent == 1 ? true : false,
        is_all: false,
        is_never: editData?.is_never,
        repeat_on: editData?.repeat_on,
        location_id: locationId,
        frequency: editData?.frequency,
        occurrences: editData?.occurrences,
        interval: editData?.interval,
        available_slots: editData?.available_slots,
      })

      if (editData?.is_never) {
        setEndsOn("Never")
      } else if (editData?.end_date != null) {
        setEndsOn("On")
        const [year, month, day] = editData?.end_date?.split('-').map(Number);
        setEndDate(new Date(year, month - 1, day))
      } else if (editData.occurrences != null) {
        setEndsOn("After")
      }

      setRepeatOn(editData?.repeat_on, editData?.frequency === "week")

    } else {
      fetchCourts();
      await fetchEventTypes();
      setFormData(initialState)
      // setDate("")
      setEndsOn("")
      if (time) {
        handleEndTimeSelect(time)
      }

      if (createDate) {

        const [day, month, year] = createDate.split("-");

        setDate(new Date(year, month - 1, day))
        setFormData((prevFormData) => ({ ...prevFormData, ["date"]: createDate }))
      }
    }
  }

  const incrementUnits = (value) => {
    if (value == "units" && formData.frequency) {
      handleChange({ name: "interval", value: formData.interval + 1 });
    } else {
      if (endsOn == "After") {
        handleChange({ name: "occurrences", value: formData.occurrences + 1 });
      }
    }

  };

  const decrementUnits = (value) => {
    if (value == "units" && formData.frequency) {
      if (formData.interval > 0) {
        handleChange({ name: "interval", value: formData.interval - 1 });
      }
    } else {
      if (formData.occurrences > 0 && endsOn == "After") {
        handleChange({ name: "occurrences", value: formData.occurrences - 1 });
      }
    }
  };

  const handleDayClick = (dayId) => {
    const selectedDay = daysOfWeek.find((day) => day.label === dayId);
    const updatedSelectedDays = selectedDays.some((day) => day.label === dayId)
      ? selectedDays.filter((day) => day.label !== dayId)
      : [...selectedDays, selectedDay];

    setSelectedDays(updatedSelectedDays);
    setFormData((prevFormData) => ({
      ...prevFormData,
      repeat_on: updatedSelectedDays.map(day => day.label),
    }));
  };

  const handleRecurrenceChange = (e) => {
    if (e.value.value == "Never") {
      setFormData((prevFormData) => ({ ...prevFormData, ["is_never"]: true }));
      setFormData((prevFormData) => ({ ...prevFormData, ["occurrences"]: null }));
      setEndDate("")
      delete formData.ends_on;
    } else if (e.value.value == "On") {
      setFormData((prevFormData) => ({ ...prevFormData, ["is_never"]: false }));
      setFormData((prevFormData) => ({ ...prevFormData, ["occurrences"]: null }));
      setEndDate("")
    } else {
      delete formData.ends_on;
      setEndDate("")
      setFormData((prevFormData) => ({ ...prevFormData, ["is_never"]: false }));
    }
    setEndsOn(e.value.value)
  };

  const formatDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'dd-MM-yyyy');
  };

  const handleFormUpdate = async () => {
    dispatch(showLoader())
    try {
      const response = await httpClient.put("/update-calender-event", formData);

      if (response?.status === 200) {
        toast_Ref.current?.showMessage(
          "success",
          edit ? "Reservation Slot Updated Successfully" : "Reservation Slot Created Successfully",
          "",
          "ic-square-check"
        )
        setDate("");
        setEndsOn("")
        setAccepted(!accepted)
        dispatch(setEventSuccess(`id-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`))
        setTimeout(() => {
          confirmationPopupRef.current?.hidePopUp();
          setFormData(initialState)
          setEditConfirmDialog(false)
          onChange()
        }, 1000);
      }

    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      confirmationPopupRef.current?.hidePopUp();
    }
    dispatch(hideLoader())
  }


  const onConfirm = async () => {
    handleFormUpdate()

  };

  const onHide = () => {
    confirmationPopupRef.current.hidePopUp();
    setEditConfirmDialog(false)
  }


  const footerContentEditDialog = (
    <div className="mt-3 flex justify-content-end align-items-center gap-3">
      <Button
        label="Cancel"
        onClick={() => setEditConfirmDialog(false)}
        className="btn-secondary w-8rem"
      />
      <Button label="Ok" onClick={() => confirmationPopupRef.current.showPopUp()} className="btn-primary w-8rem" />
    </div>
  );

  const handleRecurringChange = (e) => {
    if(e?.checked){
      setFormData((prevFormData) => ({ ...prevFormData, 
        is_recurrent: e?.checked
      })); 
    }
    else{
      setFormData((prevFormData) => ({ ...prevFormData, 
        is_recurrent: e?.checked,
        interval: 1,
        frequency: null,
        ends_on: '',
        end_date: '',
        occurrences: null
      }));

      setSelectedDays([]);
      setEndsOn('');
      setEndDate(null);
    }
  }

  return (
    <>
      <ConfirmationPopup
        ref={confirmationPopupRef}
        closable={true}
        title="Confirm"
        message={formData.is_all ? "Are you sure you want to update the reservation slots?" : "Are you sure you want to update this reservation slot?"}
        onConfirm={onConfirm}
        onCancel={onHide}
        onHide={onHide}
      />
      
      <Dialog
        header={eventName}
        visible={editConfirmDialog}
        draggable={false}
        className="success_popup edit_is_all_dialog"
        style={{ width: "33vw" }}
        onHide={() => setEditConfirmDialog(false)
        }
        footer={footerContentEditDialog}
        closable={true}
      >
        <div className="flex justify-content-start align-items-center gap-2">
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={false}
            onChange={(value) => handleChange({ name: "is_all", value: value.value })}
            checked={formData.is_all === false}
          />
          <label htmlFor="never" className="ml-2 text-base">This event</label>
          <RadioButton
            inputId="is_all"
            name="is_all"
            value={true}
            className="ml-3"
            onChange={(value) => handleChange({ name: "is_all", value: value.value })}
            checked={formData.is_all === true}
          />
          <label htmlFor="never" className="ml-2 text-base">This & Following Events</label>
        </div>
      </Dialog>
      
      <Dialog
        header=''
        className="sign_up_dialog"
        headerStyle={{ textAlign: "left", color: "#212121", fontWeight: "500" }}
        visible={visible}
        style={{ maxWidth: "540px", width: "100%" }}
        maskClassName={"addEventMask"}
        position="center"
        draggable={false}
        resizable={false}
        closable={false}
        onHide={onChange}
        onShow={onLoad}
        footer={footerContent}
      >
        <VivToast ref={toast_Ref} />
        
        <form className="max-w-full mx-auto" onSubmit={onSubmit}>
          <div className="grid">
            <div className="md:col-6 col-12">
              <FormSelect
                filter={true}
                loading={eventTypeloading}
                title={`Event Type`}
                optionValue='id'
                placeholder="Select Event Type"
                onSelect={(value) => handleChange({ name: "event_type_id", value })}
                selectedOption={formData.event_type_id}
                options={eventTypes}
              />
            </div>
            <div className="md:col-6 col-12">
              <FormSelect
                error
                title="Court"
                placeholder="Select"
                type='multi-select'
                optionValue='id'
                optionLabel="court_number"
                options={courts}
                loading={courtsLoader}
                onSelect={(value) =>
                  handleChange({ name: "court_ids", value })
                }
                selectedOption={formData.court_ids}
                disabled={courts.length < 1}
              />
            </div>
            <div className="md:col-6 col-12">
              <FormInput
                title="Token Cost"
                placeholder="Enter Token Cost"
                inputtype={"number"}
                onchange={(value) => handleChange({ name: "token_cost", value })}
                value={formData.token_cost}
              />
            </div>
            <div className="md:col-6 col-12">
              <FormInput
                name="Date"
                inputtype={"calendar"}
                dateFormat={"mm/dd/yy"}
                title="Start Date"
                placeholder="Select "
                onchange={(value) => handleChange({ name: "date", value })}
                value={date}
                minDate={new Date()}
              />
            </div>
            <div className="md:col-6 col-12">
              <FormSelect
                title={`Time Slot`}
                placeholder="Select Time Slot"
                optionValue="code"
                onSelect={(value) => {
                  const [start_time, end_time] = value.split(" to ");
                  handleChange({ name: "start_time", value: start_time });
                  handleChange({ name: "end_time", value: end_time });
                }}
                selectedOption={
                  formData.start_time && formData.end_time
                    ? `${formData.start_time} to ${formData.end_time}`
                    : formData.time_slot
                }
                options={[
                  { code: "12:00 AM to 1:00 AM", name: "12:00 AM to 1:00 AM" },
                  { code: "1:00 AM to 2:00 AM", name: "1:00 AM to 2:00 AM" },
                  { code: "2:00 AM to 3:00 AM", name: "2:00 AM to 3:00 AM" },
                  { code: "3:00 AM to 4:00 AM", name: "3:00 AM to 4:00 AM" },
                  { code: "4:00 AM to 5:00 AM", name: "4:00 AM to 5:00 AM" },
                  { code: "5:00 AM to 6:00 AM", name: "5:00 AM to 6:00 AM" },
                  { code: "6:00 AM to 7:00 AM", name: "6:00 AM to 7:00 AM" },
                  { code: "7:00 AM to 8:00 AM", name: "7:00 AM to 8:00 AM" },
                  { code: "8:00 AM to 9:00 AM", name: "8:00 AM to 9:00 AM" },
                  { code: "9:00 AM to 10:00 AM", name: "9:00 AM to 10:00 AM" },
                  { code: "10:00 AM to 11:00 AM", name: "10:00 AM to 11:00 AM" },
                  { code: "11:00 AM to 12:00 PM", name: "11:00 AM to 12:00 PM" },
                  { code: "12:00 PM to 1:00 PM", name: "12:00 PM to 1:00 PM" },
                  { code: "1:00 PM to 2:00 PM", name: "1:00 PM to 2:00 PM" },
                  { code: "2:00 PM to 3:00 PM", name: "2:00 PM to 3:00 PM" },
                  { code: "3:00 PM to 4:00 PM", name: "3:00 PM to 4:00 PM" },
                  { code: "4:00 PM to 5:00 PM", name: "4:00 PM to 5:00 PM" },
                  { code: "5:00 PM to 6:00 PM", name: "5:00 PM to 6:00 PM" },
                  { code: "6:00 PM to 7:00 PM", name: "6:00 PM to 7:00 PM" },
                  { code: "7:00 PM to 8:00 PM", name: "7:00 PM to 8:00 PM" },
                  { code: "8:00 PM to 9:00 PM", name: "8:00 PM to 9:00 PM" },
                  { code: "9:00 PM to 10:00 PM", name: "9:00 PM to 10:00 PM" },
                  { code: "10:00 PM to 11:00 PM", name: "10:00 PM to 11:00 PM" },
                  { code: "11:00 PM to 12:00 AM", name: "11:00 PM to 12:00 AM" },
                ]}
              />
            </div>

            {selectedeventType == "scheduled_play" && <div className="md:col-6 col-12">
              <FormInput
                title="No. of Slots"
                placeholder="Enter Number of Slots"
                inputtype={"number"}
                onchange={(value) => handleChange({ name: "available_slots", value })}
                value={formData.available_slots}
              />
            </div>}

            <div className="col-12 flex gap-1 align-items-center mt-4">
              <Checkbox
                onChange={(e) => handleRecurringChange(e)}
                checked={formData.is_recurrent}
                inputId="terms"
              />
              <label htmlFor="terms" className="mb-1">Recurring</label>
            </div>


            {formData.is_recurrent && (
              <>
                <Divider />
                <div className="md:col-3 col-5 flex align-items-center">
                  <p className="add-event-calender-label">Repeat Every</p>
                </div>
                <div className="md:col-2 col-4 flex align-items-center">
                  <FormInput
                    placeholder="Enter"
                    inputtype={"number"}
                    onchange={(value) => handleChange({ name: "interval", value })}
                    value={formData.interval}
                    isDisabled={!formData.frequency}
                  />
                </div>
                <div className="md:col-1 col-3 flex flex-column justify-content-center align-items-center">
                  <i className="pi pi-angle-up cursor-pointer" onClick={() => incrementUnits("units")}></i>
                  <i className="pi pi-angle-down cursor-pointer" onClick={() => decrementUnits("units")}></i>
                </div>
                <div className="md:col-3 col-5">
                  <FormSelect
                    containerclass={true}
                    placeholder="Select"
                    onSelect={(value) =>
                      handleChange({ name: "frequency", value })
                    }
                    selectedOption={formData.frequency}
                    optionValue='code'
                    options={[
                      { code: 'day', name: formData.interval > 1 ? "Days" : "Day" },
                      { code: 'week', name: formData.interval > 1 ? "Weeks" : "Week" },
                      { code: 'month', name: formData.interval > 1 ? "Months" : "Month" },
                      { code: 'year', name: formData.interval > 1 ? "Years" : "Year" },
                    ]}
                  />
                </div>
                {formData?.frequency?.includes("week") &&
                  (
                    <>
                      <div className="col-12">
                        <p className="add-event-calender-label">Repeat on</p>
                      </div>
                      <div className="col-12">
                        <div className="days-add-event">
                          {daysOfWeek.map((day) => (
                            <div
                              key={day.label}
                              className={`day-circle-add-event ${selectedDays.some(selectedDay => selectedDay.label === day.label) ? 'selected' : ''}`}
                              onClick={() => handleDayClick(day.label)}
                            >
                              {day.name}
                            </div>
                          ))}
                        </div>
                      </div>

                    </>
                  )}

                <div className="col-12">
                  <p className="add-event-calender-label">Ends on</p>
                </div>
                <div className="col-12">
                  <div className="flex align-items-center mb-2">
                    <RadioButton
                      inputId="never"
                      name="recurrence"
                      value="Never"
                      onChange={(value) => handleRecurrenceChange({ name: "ends_on", value })}
                      checked={endsOn === 'Never'}
                    />
                    <label htmlFor="never" className="ml-2 add-event-calender-label">Never</label>
                  </div>

                  <div className="flex align-items-center mb-2">
                    <RadioButton
                      inputId="on"
                      name="recurrence"
                      value="On"
                      onChange={(value) => handleRecurrenceChange({ name: "ends_on", value })}
                      checked={endsOn === 'On'}
                    />
                    <label htmlFor="on" className="ml-2 mr-5 add-event-calender-label">On</label>
                    <FormInput
                      name="Date"
                      inputtype={"calendar"}
                      title=""
                      minDate={new Date()}
                      dateFormat={"mm/dd/yy"}
                      placeholder="Select"
                      onchange={(value) => handleChange({ name: "end_date", value })}
                      value={endDate}
                      isDisabled={endsOn !== 'On'}
                      styleclass={"w-5"}
                    />
                  </div>

                  <div className="flex align-items-center mb-2">
                    <RadioButton
                      inputId="after"
                      name="recurrence"
                      value="After"
                      onChange={(value) => handleRecurrenceChange({ name: "ends_on", value })}
                      checked={endsOn === 'After'}
                    />
                    <label htmlFor="after" className="ml-2 mr-3 add-event-calender-label">After</label>
                    <div className="flex justify-content-start align-items-center relative">
                      <FormInput
                        placeholder="Enter"
                        inputtype={"number"}
                        onchange={(value) => handleChange({ name: "occurrences", value })}
                        value={formData.occurrences}
                        styleclass={"w-full"}
                        isDisabled={endsOn != "After"}
                      />
                      <div className="col-1 flex flex-column justify-content-center align-items-center ml-3">
                        <i className={`pi pi-angle-up cursor-pointer ${endsOn !== "After" ? 'disabled-increment-btn' : ''}`} onClick={() => incrementUnits("occurrence")}></i>
                        <i className={`pi pi-angle-down cursor-pointer ${endsOn !== "After" ? 'disabled-increment-btn' : ''}`} onClick={() => decrementUnits("occurrence")}></i>
                      </div>
                      <span className={`absolute occurance ${endsOn !== "After" ? 'disabled-increment-btn' : ''}`}>{formData.occurrences > 1 ? "occurrences" : "occurrence"}</span>
                    </div>
                  </div>
                </div>
              </>
            )}

          </div>

        </form>
      </Dialog>
    </>
  );
}
